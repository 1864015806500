import { Box, FormHelperText, Stack, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import useLocales from '#/hooks/useLocales';
import { LastWillValues } from '#/types/lastWill';
import { distributionQuestions } from '#/components/pages/LastWill/steps/content/stepsData';
import {
  StyledFormControlLabel,
  StyledRadio,
  StyledRadioGroup,
} from '#/components/pages/PensionProvision/styles';
import { useGetChildren } from '#/api/childrenQueries';

const Distribution = () => {
  const {
    setValue,
    watch,
    control,
    formState: { errors },
  } = useFormContext();
  const { translate } = useLocales();

  const { data: childrenList } = useGetChildren();

  const refs = useRef<{ [key: string]: HTMLElement | null }>({});

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const errorKeys = Object.keys(errors);

      const errorElements = errorKeys
        .map((key) => refs.current[key])
        .filter((ref) => ref !== undefined && ref !== null);

      if (errorElements.length > 0) {
        const highestErrorElement = errorElements.reduce((highest, current) => {
          const currentTop = current ? current.getBoundingClientRect().top : 0;
          const highestTop = highest ? highest.getBoundingClientRect().top : 0;

          return currentTop < highestTop ? current : highest;
        }, errorElements[0]);

        if (highestErrorElement) {
          highestErrorElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        }
      }
    }
  }, [errors]);

  const handleOnChange = (
    questionName: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue?.(questionName, event.target.value);
  };

  const handleDefaultValues = (question: string) =>
    watch?.(`${question}` as keyof LastWillValues);

  const tNonMDistQuestions = distributionQuestions.map((question, i) => ({
    ...question,
    question: translate(`lastWill.stepTwo.questions.${i}.question`),
    answers: question?.answers?.map((answer, j) => ({
      ...answer,
      label: translate(`lastWill.stepTwo.questions.${i}.options.${j}.label`),
    })),
  }));

  const showChildren = (question: string) => {
    const value = watch?.(`${question}` as keyof LastWillValues);
    if (question === 'permitted_law' && value === 'institution') {
      return true;
    }
    return String(value) === 'yes';
  };

  const handleRadioDisable = (questionRadio: string, answerOption: string) => {
    const children_no = childrenList?.length || 0;
    const partner = watch?.('living_partnership');

    const noPartnerOption =
      partner === 'no' &&
      answerOption === 'registeredPartnership' &&
      questionRadio === 'permitted_law';

    const noChildOption =
      children_no < 1 &&
      answerOption === 'children' &&
      questionRadio === 'permitted_law';
    if (noPartnerOption || noChildOption) {
      return true;
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="body1" sx={{ my: 2 }}>
        {highlightWords(String(translate('lastWill.stepTwo.introParagraph')), [
          String(translate('lastWill.stepTwo.mainly')),
        ])}
      </Typography>
      <Stack spacing={5}>
        {tNonMDistQuestions.map((question, index) => (
          <div
            key={question.id}
            ref={(ref) => {
              refs.current[`${question.value}`] = ref;
            }}
          >
            <Stack key={`step-${question.id}`} spacing={2}>
              <Controller
                name={question.value}
                control={control}
                defaultValue={handleDefaultValues(question.value)}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <StyledRadioGroup
                    aria-label={question.value}
                    name={question.value}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      handleOnChange(question.value, e);
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ mb: 1, mt: index === 0 ? 2 : 0 }}
                    >
                      {String(question.question)}
                    </Typography>
                    <Stack
                      direction={{
                        xs: 'column',
                        md: 'row',
                      }}
                      flexDirection="row"
                      spacing={2}
                    >
                      {question?.answers &&
                        question?.answers
                          ?.filter(
                            (option) =>
                              !handleRadioDisable(question.value, option.value)
                          )
                          .map((option) => (
                            <StyledFormControlLabel
                              key={`step-${question.id}-${option.value}`}
                              value={option.value}
                              control={<StyledRadio />}
                              label={String(option.label)}
                            />
                          ))}
                    </Stack>
                    {!!error && question.answers && (
                      <FormHelperText error={!!error} sx={{ mx: 0 }}>
                        {error && error?.message}
                      </FormHelperText>
                    )}
                  </StyledRadioGroup>
                )}
              />
              {showChildren(`${question.value}`) && question.children && (
                <div
                  ref={(ref) => {
                    refs.current[`${question.children.value}`] = ref;
                  }}
                >
                  {question.children.content()}
                </div>
              )}
            </Stack>
          </div>
        ))}
      </Stack>
    </Box>
  );
};

function highlightWords(sentence: string, targetPhrases: string[]) {
  let elements: React.ReactNode[] = [sentence];
  targetPhrases.forEach((phrase, i) => {
    elements = elements.flatMap((el, j) => {
      if (typeof el === 'string') {
        const parts = el.split(new RegExp(`(${phrase})`, 'gi'));
        return parts.map((part, k) =>
          part.toLowerCase() === phrase.toLowerCase() ? (
            <span
              key={`${i}-${j}-${k}`}
              style={{ textDecoration: 'underline' }}
            >
              {part}
            </span>
          ) : (
            part
          )
        );
      }
      return el;
    });
  });
  return elements;
}

export default Distribution;
