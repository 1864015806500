import { Button, Paper, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DialogAnimate from '#/components/shared/animate/DialogAnimate';
import useLocales from '#/hooks/useLocales';

type Props = {
  open: boolean;
  closeDialog: () => void;
  resetNumber: () => void;
};

export const ChildrenWarningModal = ({
  open,
  closeDialog,
  resetNumber,
}: Props) => {
  const { translate } = useLocales();

  return (
    <DialogAnimate open={open} onClose={closeDialog}>
      <Paper
        sx={{
          padding: 2,
        }}
      >
        <Typography variant="subtitle1" gutterBottom>
          {String(translate('lastWill.noChildrenModal.title'))}
        </Typography>
        <Typography variant="caption" gutterBottom>
          {String(translate('lastWill.noChildrenModal.description'))}
        </Typography>

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems="center"
          spacing={2}
          justifyContent="center"
          sx={{ marginTop: 3 }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={closeDialog}
            size="small"
            sx={{ width: 1 }}
          >
            <Typography variant="body2">
              {String(translate('lastWill.noChildrenModal.cancelBtn'))}
            </Typography>
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={resetNumber}
            size="small"
            sx={{ width: 1 }}
          >
            <Typography variant="body2">
              {String(translate('lastWill.noChildrenModal.resetNumberBtn'))}
            </Typography>
          </Button>
        </Stack>
      </Paper>
    </DialogAnimate>
  );
};

type RelationshipModalProps = {
  open: boolean;
  closeDialog: () => void;
  updateChildrenRelationship: () => void;
  cancel: () => void;
  isLoading?: boolean;
};

export const CheckChildrenRelationship = ({
  open,
  closeDialog,
  updateChildrenRelationship,
  cancel,
  isLoading,
}: RelationshipModalProps) => {
  const { translate } = useLocales();

  return (
    <DialogAnimate
      open={open}
      onClose={closeDialog}
      disableEscapeKeyDown
      onClick={(e) => e.stopPropagation()}
    >
      <Paper
        sx={{
          padding: 2,
        }}
      >
        <Typography variant="subtitle1" gutterBottom>
          {String(translate('lastWill.checkChildrenRelationship.title'))}
        </Typography>
        <Typography variant="body2" gutterBottom textAlign="center">
          {String(translate('lastWill.checkChildrenRelationship.description'))}
        </Typography>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems="center"
          spacing={2}
          justifyContent="center"
          sx={{ marginTop: 3 }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={cancel}
            size="small"
            sx={{ width: 1 }}
          >
            <Typography variant="body2">
              {String(
                translate('lastWill.checkChildrenRelationship.cancelButton')
              )}
            </Typography>
          </Button>
          <LoadingButton
            variant="contained"
            onClick={updateChildrenRelationship}
            size="small"
            sx={{ width: 1 }}
            loading={isLoading}
          >
            <Typography variant="body2">
              {String(
                translate('lastWill.checkChildrenRelationship.updateButton')
              )}
            </Typography>
          </LoadingButton>
        </Stack>
      </Paper>
    </DialogAnimate>
  );
};
