import {
  Container,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Card,
  Typography,
  Button,
  Divider,
  Box,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import React, { useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import useLocales from '#/hooks/useLocales';
import { stepsData } from './steps/content/stepsData';
import useStepper from '#/hooks/useStepper';
import { QontoConnector } from '#/components/pages/LivingWill/Steps/styles';
import FormProvider from '#/components/shared/hook-form/FormProvider';
import QontoStepIcon from '#/components/pages/LivingWill/Steps/QontoStepIcon';
import EnoughForNowModal from '#/components/shared/ui/EnoughForNowModal';
import {
  allValuesReset,
  schemaValues,
  stepOneSchema,
  stepThreeSchema,
  stepTwoSchema,
} from '#/components/pages/LastWill/schemaValidation';
import { LastWillValues } from '#/types/lastWill';
import {
  invalidateServicesStatsQueries,
  useGetServiceForm,
  useStartService,
} from '#/api/servicesQueries';
import useServiceStats from '#/hooks/useServiceStats';
import useAuth from '#/hooks/useAuth';
import { useGetRepresentatives } from '#/api/representativeQueries';
import {
  CheckChildrenRelationship,
  ChildrenWarningModal,
} from '#/components/pages/LastWill/steps/content/Inheritors/childrens/ChildrenWarningModal';
import {
  invalidateChildrenQueries,
  useDeleteAllChildren,
  useDeleteMultipleChildren,
  useGetChildren,
} from '#/api/childrenQueries';
import RequiredBasicInfo from '#/components/pages/User/RequiredBasicInfo';
import ModalWrapper from '#/components/shared/advisor-helpers/modal-wrapper';
import AdvisorsListView from '#/components/shared/advisor-helpers/partner/advisors-list-view';
import NonPartnerView from '#/components/shared/advisor-helpers/non-partner/non-partner-view';
import MarkAsDone from '#/components/shared/mark-as-done';
import { fToDB } from '#/utils/formatTime';
import VideoTutorial from '#/components/shared/advisor-helpers/video-tutorial';
// import UpdateCivilStatus from '#/components/pages/LastWill/steps/content/Inheritors/UpdateCivilStatus';

const LastWillForm = () => {
  const [openEnoughNow, setOpenEnoughNow] = useState(false);
  const [stopProcessModal, setStopProcessModal] = useState(false);
  const [openResetModal, setOpenResetModal] = useState(false);
  // const [openChangeCivilStatus, setOpenChangeCivilStatus] = useState(false);
  const [checkChildren, setCheckChildren] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();

  const { last_will } = useServiceStats();
  const { user } = useAuth();
  const { data: children } = useGetChildren();
  const { data: checkForRepresentative } = useGetRepresentatives();

  const { data: dbFormValues } = useGetServiceForm('last-will/get');
  const { mutateAsync: initialiseLastWill, isLoading } =
    useStartService('last-will');
  const { mutateAsync: deleteAllChildren, isSuccess: allChildrenDeleted } =
    useDeleteAllChildren();
  const {
    mutateAsync: deleteMultipleChildren,
    isLoading: deletingMultipleChildren,
  } = useDeleteMultipleChildren();

  const relationshipRepresentative = checkForRepresentative?.find(
    (representative) =>
      representative.relationship === 'wife' ||
      representative.relationship === 'husband_wife'
  );

  const isMarried = user?.civil_status === 'married';

  const handleOpenResetModal = () => setOpenResetModal(true);
  const handleCloseResetModal = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    setValue('have_children', 'yes');
    setOpenResetModal(false);
  };

  const handleCheckChildren = () => setCheckChildren(true);
  const handleCloseCheckChildren = () => setCheckChildren(false);

  // const handleOpenChangeCivilStatus = () => setOpenChangeCivilStatus(true);
  // const handleCloseChangeCivilStatus = () => setOpenChangeCivilStatus(false);

  const handleOpenStopProcessModal = () => setStopProcessModal(true);
  const handleCloseStopProcessModal = () => {
    setStopProcessModal(false);
    window.open('https://www.velartis.ch/Kontakt.htm', '_blank');
    navigate('/dashboard/home');
  };

  const handleOpenEnoughNow = () => setOpenEnoughNow(true);

  const handleCloseEnoughNow = () => setOpenEnoughNow(false);

  const { activeStep, handleNext, handleBack, setActiveStep } = useStepper(
    false,
    'last_will',
    stepsData
  );
  const { translate } = useLocales();

  const formSchemas = [
    stepOneSchema(user?.civil_status || 'married'),
    stepTwoSchema,
    stepThreeSchema,
  ];

  const defaultValues = useMemo(
    () => schemaValues(dbFormValues, activeStep),
    [dbFormValues, activeStep]
  );

  const methods = useForm<LastWillValues>({
    resolver: formSchemas[activeStep]
      ? yupResolver(formSchemas[activeStep] as any)
      : undefined,
    defaultValues,
  });

  const { reset, handleSubmit, watch, setValue } = methods;

  const handleChangeAnswer = () => {
    setValue('marriage_or_partnership_contract', 'no');
    setStopProcessModal(false);
  };

  const submitForm = async ({
    data = watch(),
    startOver,
  }: {
    data: any;
    startOver?: boolean;
  }) => {
    const setEmptyToNull = (obj: any): any => {
      if (obj && typeof obj === 'object') {
        if (Array.isArray(obj)) {
          return obj.map(setEmptyToNull);
        }
        return Object.fromEntries(
          Object.entries(obj).map(([key, value]) => {
            if (value === '' || value === undefined || value === null) {
              return [key, null];
            }
            return [key, setEmptyToNull(value)];
          })
        );
      }
      return obj;
    };

    const transformedData = setEmptyToNull(data);

    let reqData: any;

    if (startOver) {
      reqData = {
        ...transformedData,
        ...allValuesReset,
      };
    } else {
      reqData = {
        ...transformedData,
        partner_or_spouse_date_of_birth: fToDB(
          data.partner_or_spouse_date_of_birth
        ),
        children:
          Number(data?.children_no) > 0
            ? // @ts-expect-error
              data.children.map((child) => ({
                ...child,
                date_of_birth: fToDB(child.date_of_birth),
              }))
            : [],
      };
    }

    await initialiseLastWill(reqData).then(() => {
      invalidateServicesStatsQueries.getServicesStats();
      invalidateServicesStatsQueries.getServiceForm('last-will/get');
      handleNext();
    });
  };

  const onSubmit = async (data: any) => {
    await submitForm({ data });
    scrollToTop();
  };

  const translatedStepsData = stepsData.map((step, i) => ({
    ...step,
    label: translate(`lastWill.stepperInfo.${i}.label`),
    title: translate(`lastWill.stepperInfo.${i}.title`),
  }));

  const shortPainFree =
    activeStep === 0 || activeStep === 1 || activeStep === 2;
  const madeIt = activeStep === 3;

  useEffect(() => {
    if (dbFormValues) {
      reset(defaultValues);
    }
    if (activeStep) {
      reset(defaultValues);
    }
  }, [dbFormValues, activeStep]);

  useEffect(() => {
    if (
      watch('marriage_or_partnership_contract') === 'yes' &&
      activeStep === 0
    ) {
      handleOpenStopProcessModal();
    }
  }, [watch('marriage_or_partnership_contract'), activeStep]);

  useEffect(
    () => () => {
      const submit = async () => {
        await submitForm({ data: watch() });
      };

      submit().then((r) => r);
    },
    []
  );

  useEffect(() => {
    if (isMarried && relationshipRepresentative) {
      setValue(
        'partner_or_spouse_first_name',
        relationshipRepresentative?.first_name || ''
      );
      setValue(
        'partner_or_spouse_last_name',
        relationshipRepresentative?.last_name || ''
      );
    }
  }, [isMarried, relationshipRepresentative]);

  useEffect(() => {
    if (allChildrenDeleted) {
      invalidateChildrenQueries.getChildren();
      setValue('children_no', 0);
    }
  }, [allChildrenDeleted]);

  const childrens = children?.length;

  useEffect(() => {
    if (
      String(watch('have_children')) === 'no' &&
      childrens &&
      childrens > 0 &&
      activeStep === 0
    ) {
      handleOpenResetModal();
    }
  }, [watch('have_children'), childrens, activeStep]);

  const changedLivingPartnership = watch('living_partnership');

  useEffect(() => {
    const childrenFromCurrentRelationship = children?.filter(
      (child) => child.from_commonMarriage === 'yes'
    );
    if (
      activeStep === 0 &&
      changedLivingPartnership === 'no' &&
      childrenFromCurrentRelationship &&
      childrenFromCurrentRelationship?.length > 0
    ) {
      handleCheckChildren();
    }
  }, [activeStep, changedLivingPartnership]);

  useEffect(() => {
    if (state?.startOver) {
      setActiveStep(0);
      submitForm({ data: watch(), startOver: true });
    }
  }, [state?.startOver]);

  const closeResetModal = async () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    setValue('have_children', 'no');
    await deleteAllChildren(0).then(() => {
      setOpenResetModal(false);
    });
  };

  const updateChildrenRelationship = async () => {
    const childrenFromCurrentRelationship = children?.filter(
      (child) => child.from_commonMarriage === 'yes'
    );
    await deleteMultipleChildren({
      ids: childrenFromCurrentRelationship?.map((child) => child.id),
    }).then(() => {
      invalidateChildrenQueries.getChildren();
      handleCloseCheckChildren();
    });
  };

  const cancelChildrenUpdate = () => {
    setValue('living_partnership', 'yes');
    handleCloseCheckChildren();
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const basicInfoMissing =
    user?.civil_status === null ||
    user?.civil_status === undefined ||
    user?.civil_status === '';

  if (
    state?.step === 'markAsDone' ||
    state?.step === 'history' ||
    state?.step === 'uploadDocument'
  ) {
    return (
      <MarkAsDone
        serviceName="last-will"
        documentType="lastWill"
        serviceInvalidateKey="last-will/get"
      />
    );
  }

  return (
    <Container maxWidth="lg">
      {shortPainFree && (
        <Typography variant="h2" mb={5} textAlign="center">
          {String(translate('lastWill.mainHeaders.shortPainFree'))}
        </Typography>
      )}
      {madeIt && (
        <Typography variant="h2" mb={5} textAlign="center">
          {String(translate('lastWill.mainHeaders.madeIt'))}
        </Typography>
      )}
      <Stack sx={{ width: '100%' }} spacing={4}>
        {basicInfoMissing && activeStep === 0 && <RequiredBasicInfo />}
        {!basicInfoMissing && (
          <Box>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<QontoConnector />}
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'flex',
                  },
                }}
              >
                {translatedStepsData?.map((step, i) => (
                  <Step
                    key={`step-${step.title}`}
                    onClick={() => {
                      if (i < activeStep) {
                        setActiveStep(i);
                      }
                      if (last_will?.service?.completed) {
                        setActiveStep(i);
                      }
                    }}
                    sx={{
                      ...((i < activeStep || last_will?.service?.completed) && {
                        cursor: 'pointer',
                      }),
                    }}
                  >
                    <StepLabel StepIconComponent={QontoStepIcon}>
                      {String(step.label)}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Card sx={{ p: 3, mt: 4 }}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="subtitle1" mb={2}>
                    {String(translatedStepsData[activeStep]?.title)}
                  </Typography>
                  {activeStep !== translatedStepsData.length - 1 && (
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      sx={{ mb: 2 }}
                      onClick={handleOpenEnoughNow}
                    >
                      <Typography
                        variant="body1"
                        color="error"
                        sx={{ cursor: 'pointer' }}
                      >
                        {String(translate('global.enoughForNow'))}
                      </Typography>
                    </Button>
                  )}
                </Stack>
                <Divider
                  sx={{
                    mb: 3,
                  }}
                />
                {translatedStepsData[activeStep]?.content(dbFormValues)}

                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={2}
                  justifyContent="flex-end"
                  sx={{
                    mt: 3,
                  }}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    <Typography variant="body1">
                      {String(translate('global.back'))}
                    </Typography>
                  </Button>
                  <LoadingButton
                    variant="contained"
                    color={
                      activeStep === translatedStepsData.length - 1
                        ? 'success'
                        : 'primary'
                    }
                    type="submit"
                    loading={isLoading}
                    onClick={() => {
                      if (activeStep === translatedStepsData.length - 1) {
                        navigate('/dashboard/home');
                      }
                    }}
                    sx={{
                      color: '#fff',
                    }}
                  >
                    <Typography variant="body1">
                      {activeStep === translatedStepsData.length - 1
                        ? String(translate('global.finish'))
                        : String(translate('global.next'))}
                    </Typography>
                  </LoadingButton>
                </Stack>
              </Card>
            </FormProvider>
          </Box>
        )}
      </Stack>
      <EnoughForNowModal
        openEnoughNow={openEnoughNow}
        handleCloseEnoughNow={handleCloseEnoughNow}
      />
      <ModalWrapper
        open={stopProcessModal}
        onClose={handleCloseStopProcessModal}
        modalTitle="global.complexFormModal.title"
        modalDescription="global.complexFormModal.description"
      >
        {user?.partner ? (
          <>
            <VideoTutorial youtubeLink="https://youtu.be/Z3l0cRnhk-c" />

            <AdvisorsListView
              advisorType="legal"
              isComplexForm
              changeAnswer={handleChangeAnswer}
              closeModal={() => setStopProcessModal(false)}
            />
          </>
        ) : (
          <NonPartnerView
            advisorImage="/assets/images/getyourlawyer_logo.svg"
            isComplexForm
            changeAnswer={handleChangeAnswer}
            handleContinueProcess={handleCloseStopProcessModal}
            isFinancialAdvisor={false}
          />
        )}
      </ModalWrapper>
      <ChildrenWarningModal
        open={openResetModal}
        closeDialog={handleCloseResetModal}
        resetNumber={closeResetModal}
      />
      <CheckChildrenRelationship
        open={checkChildren}
        closeDialog={handleCloseCheckChildren}
        updateChildrenRelationship={updateChildrenRelationship}
        cancel={cancelChildrenUpdate}
        isLoading={deletingMultipleChildren}
      />
      {/* {enableCivilStatusModal && activeStep === 0 && (
        <UpdateCivilStatus
          open={openChangeCivilStatus}
          closeDialog={handleCloseChangeCivilStatus}
          restoreValue={handleValueReset}
          civilStatus={updateToStatus}
        />
      )} */}
    </Container>
  );
};

export default LastWillForm;
