import { Button, MenuItem, Stack, Typography, Skeleton } from '@mui/material';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import React, { useEffect, useMemo } from 'react';
import useLocales from '#/hooks/useLocales';
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from '#/components/shared/hook-form';
import { useGetBeneficiaries } from '#/api/beneficiaries';
import Iconify from '#/components/shared/ui/Iconify';
import { IGoods } from '#/types/propertyAssets';
import {
  invalidateServicesStatsQueries,
  useAddGood,
  useGetGoodDetail,
  useUpdateGood,
} from '#/api/servicesQueries';
import Beneficiaries from '#/components/pages/LastWill/steps/content/Inheritors/childrens/Beneficiary/Beneficiaries';

type Props = {
  isEdit?: boolean;
  goodID?: string | number | undefined;
  setIsEdit?: (isEdit: boolean) => void;
  setAddNew?: (addNew: boolean) => void;
  setNewBeneficiary?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  newBeneficiary?: boolean;
};

const GoodsForm = ({
  isEdit,
  goodID,
  setIsEdit,
  setAddNew,
  setNewBeneficiary,
  newBeneficiary,
}: Props) => {
  const { data: beneficiaries, isLoading } = useGetBeneficiaries();
  const { data: goodDetails } = useGetGoodDetail('last-will', goodID, !!goodID);
  const { mutateAsync: addGood } = useAddGood('last-will');
  const { mutateAsync: updateGood } = useUpdateGood('last-will', goodID);
  const { translate } = useLocales();

  const defaultValues = useMemo(
    () => ({
      storage_location: goodDetails?.storage_location || '',
      beneficiary:
        goodDetails?.beneficiary ||
        // beneficiaries?.[beneficiaries.length - 1]?.id ||
        '',
      description: goodDetails?.description || '',
    }),
    [goodDetails, beneficiaries]
  );

  const methods = useForm<IGoods>({
    defaultValues,
  });

  const { handleSubmit } = methods;

  useEffect(() => {
    if (isEdit && goodDetails) {
      methods.reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [isEdit, goodDetails]);

  // useEffect(() => {
  //   if (beneficiaries) {
  //     setValue('beneficiary', beneficiaries?.[beneficiaries.length - 1]?.id);
  //   }
  //   // eslint-disable-next-line
  // }, [beneficiaries]);

  const onSubmit = async (data: IGoods) => {
    if (isEdit) {
      await updateGood(data).then(() => {
        invalidateServicesStatsQueries.getGoods('last-will');
        methods.reset(defaultValues);
        setIsEdit?.(false);
        toast.success(
          String(translate('toast_notifications.success.good_update'))
        );
      });
    } else {
      await addGood(data).then(() => {
        methods.reset(defaultValues);
        invalidateServicesStatsQueries.getGoods('last-will');
        setAddNew?.(false);
        toast.success(
          String(translate('toast_notifications.success.good_update'))
        );
      });
    }
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {newBeneficiary ? (
        <Beneficiaries
          externalRequest={newBeneficiary}
          setNewBeneficiary={setNewBeneficiary}
        />
      ) : (
        <FormProvider methods={methods}>
          <Stack spacing={2}>
            <Typography variant="body1">
              {String(translate('lastWill.stepTwo.followingDistribution'))}
            </Typography>
            <Stack
              direction={{
                xs: 'column',
                sm: 'row',
              }}
              spacing={2}
            >
              <RHFTextField
                name="description"
                label={String(translate('global.description'))}
              />

              <RHFTextField
                name="storage_location"
                label={String(translate('global.storageLocation'))}
              />
            </Stack>
            <Stack
              direction={{
                xs: 'column',
                sm: 'row',
              }}
              spacing={2}
              alignItems="center"
            >
              {isLoading ? (
                <Skeleton
                  animation="wave"
                  sx={{
                    borderRadius: '25px',
                  }}
                />
              ) : (
                <RHFSelect
                  name="beneficiary"
                  label={String(translate('global.beneficiarie'))}
                >
                  <MenuItem onClick={() => setNewBeneficiary?.(true)}>
                    <Iconify icon="mdi:account-plus" color="text.secondary" />
                    <Typography color="text.secondary">
                      {String(translate('global.addNewBeneficiary'))}
                    </Typography>
                  </MenuItem>
                  {beneficiaries?.map((beneficiary) => (
                    <MenuItem key={beneficiary.id} value={beneficiary.id}>
                      {beneficiary.name}
                    </MenuItem>
                  ))}
                </RHFSelect>
              )}
              {/* <Button */}
              {/*  variant="outlined" */}
              {/*  color="secondary" */}
              {/*  onClick={() => setNewBeneficiary?.(true)} */}
              {/* > */}
              {/*  <Iconify icon="mdi:plus-circle" /> */}
              {/* </Button> */}
            </Stack>
            <Stack
              direction={{
                xs: 'column',
                sm: 'row',
              }}
              sx={{
                width: '100%',
              }}
              spacing={2}
            >
              <Button
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                type="submit"
                sx={{
                  width: '120px',
                }}
                size="small"
                color="secondary"
                startIcon={<Iconify icon="mdi:content-save" />}
              >
                <Typography variant="caption">
                  {String(translate('global.save'))}
                </Typography>
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  methods.reset(defaultValues);
                  setIsEdit?.(false);
                  setAddNew?.(false);
                }}
                size="small"
                startIcon={<Iconify icon="mdi:close" />}
              >
                <Typography variant="caption">
                  {String(translate('global.cancel'))}
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </FormProvider>
      )}
    </>
  );
};

export default GoodsForm;
