import { Text, View } from '@react-pdf/renderer';
import useLocales from '#/hooks/useLocales';
import styles from '#/components/pages/LivingWill/Steps/content/YourLivingWill/styles';
import { fDate } from '#/utils/formatTime';

type Props = {
  living_partnership: string;
  gender: string;
  clientChildrens: any[];
  partner_property_address: string;
  partner_property: string;
  partnerMainInfo: string;
  partnerBirthday: string;
};
const SpouseVariant = ({
  living_partnership,
  gender,
  clientChildrens,
  partner_property_address,
  partner_property,
  partnerMainInfo,
  partnerBirthday,
}: Props) => {
  const { translate } = useLocales();

  const childsFromMarriage = clientChildrens?.filter(
    (child: any) => child?.from_commonMarriage === 'yes'
  );

  const childsFromOtherMarriage = clientChildrens?.filter(
    (child: any) => child?.from_commonMarriage === 'no'
  );

  const isOnlyChildFromMarriage = childsFromMarriage?.length === 1;
  const isOnlyChildFromOtherMarriage = childsFromOtherMarriage?.length === 1;

  return (
    <View style={styles.pointParagraphs}>
      <Text>3.</Text>
      <View style={styles.pointContent}>
        <Text>
          {String(
            translate(
              `lastWill.stepFour.document.p4MarriedSpouse.${
                living_partnership === 'yes'
                  ? 'livingPartnershipStatus'
                  : 'marriedStatus'
              }.${gender || 'male'}`
            )
          )}
          {', '}
          {partnerMainInfo}
          {String(
            translate('lastWill.stepFour.document.p4MarriedSpouse.born', {
              birthday: partnerBirthday,
            })
          )}
          {String(
            translate('lastWill.stepFour.document.p4MarriedSpouse.receives')
          )}{' '}
          {String(
            translate(
              `lastWill.stepFour.document.p4MarriedSpouse.partnershipContract.no`
            )
          )}{' '}
          {String(
            translate('lastWill.stepFour.document.p4MarriedSpouse.quota')
          )}
          {', '}
          {String(
            translate(
              `lastWill.stepFour.document.p4MarriedSpouse.noContractButChildren.main`,
              {
                children: isOnlyChildFromMarriage
                  ? translate(
                      `lastWill.stepFour.document.p4MarriedSpouse.noContractButChildren.oneChild`
                    )
                  : translate(
                      `lastWill.stepFour.document.p4MarriedSpouse.noContractButChildren.multipleChildren`
                    ),
              }
            )
          )}
          {/* {childsFromMarriage?.map((child: any, i) => ( */}
          {/*  <Text key={child.id}> */}
          {/*    {i !== 0 && ' '} */}
          {/*    {child.first_name} {child.last_name} */}
          {/*    {String( */}
          {/*      translate(`lastWill.stepFour.document.p4MarriedSpouse.born`, { */}
          {/*        birthday: fDate(child?.date_of_birth, 'dd. MMM yyyy', 'de'), */}
          {/*      }) */}
          {/*    )} */}
          {/*    {getConjunction( */}
          {/*      i, */}
          {/*      childsFromMarriage?.length, */}
          {/*      String(translate('lastWill.stepFour.document.and')) */}
          {/*    )} */}
          {/*  </Text> */}
          {/* ))} */}
          {String(
            translate(
              `lastWill.stepFour.document.p4MarriedSpouse.noContractButChildren.receive`
            )
          )}
        </Text>

        {partner_property === 'yes' && (
          <Text>
            {String(
              translate(
                `lastWill.stepFour.document.p5MarriedSpouse.${
                  living_partnership === 'yes'
                    ? 'livingPartnershipStatus'
                    : 'marriedStatus'
                }.${gender || 'male'}`
              )
            )}{' '}
            {String(
              translate(
                'lastWill.stepFour.document.p5MarriedSpouse.particularReceives'
              )
            )}{' '}
            {String(
              translate(
                'lastWill.stepFour.document.p5MarriedSpouse.theProperty'
              )
            )}{' '}
            {String(
              translate(
                'lastWill.stepFour.document.p5MarriedSpouse.atTheAddress'
              )
            )}{' '}
            {partner_property_address}{' '}
            {String(
              translate(
                'lastWill.stepFour.document.p5MarriedSpouse.assignedToProperty'
              )
            )}
          </Text>
        )}
        {childsFromMarriage?.length !== 0 && (
          <Text style={{ display: 'flex' }}>
            <Text>
              {`${String(
                translate(
                  `lastWill.stepFour.document.p6MarriedSpouse.${
                    isOnlyChildFromMarriage ? 'oneChild' : 'multipleChildren'
                  }.commonMarriage`
                )
              )}`}
            </Text>
            <Text style={{ display: 'flex' }}>
              {childsFromMarriage?.map((child: any, i) => (
                <Text style={{ marginLeft: 2 }} key={child.id}>
                  {i !== 0 && ' '}
                  {child.first_name} {child.last_name}
                  {String(
                    translate(
                      `lastWill.stepFour.document.p6MarriedSpouse.${
                        isOnlyChildFromMarriage
                          ? 'oneChild.born'
                          : 'multipleChildren.born'
                      }`,
                      {
                        birthday: fDate(
                          child?.date_of_birth,
                          'dd. MMM yyyy',
                          'de'
                        ),
                      }
                    )
                  )}
                  {getConjunction(
                    i,
                    childsFromMarriage?.length,
                    String(translate('lastWill.stepFour.document.and'))
                  )}
                </Text>
              ))}
            </Text>
            <Text>
              {String(
                translate(
                  `lastWill.stepFour.document.p6MarriedSpouse.${
                    isOnlyChildFromMarriage ? 'oneChild' : 'multipleChildren'
                  }.receives`
                )
              )}
            </Text>
            <Text>
              {String(
                translate('lastWill.stepFour.document.p6MarriedSpouse.final')
              )}
            </Text>
          </Text>
        )}

        {childsFromOtherMarriage?.length !== 0 && (
          <Text style={{ display: 'flex' }}>
            <Text>
              {`${String(
                translate(
                  `lastWill.stepFour.document.p6MarriedSpouse.${
                    isOnlyChildFromOtherMarriage
                      ? 'oneChild'
                      : 'multipleChildren'
                  }.otherMarriage`
                )
              )}`}
            </Text>
            <Text style={{ display: 'flex' }}>
              {childsFromOtherMarriage?.map((child: any, i) => (
                <Text style={{ marginLeft: 2 }} key={child.id}>
                  {i !== 0 && ' '}
                  {child.first_name} {child.last_name}
                  {String(
                    translate(
                      `lastWill.stepFour.document.p6MarriedSpouse.${
                        isOnlyChildFromOtherMarriage
                          ? 'oneChild.born'
                          : 'multipleChildren.born'
                      }`,
                      {
                        birthday: fDate(
                          child?.date_of_birth,
                          'dd. MMM yyyy',
                          'de'
                        ),
                      }
                    )
                  )}
                  {getConjunction(
                    i,
                    childsFromOtherMarriage?.length,
                    String(translate('lastWill.stepFour.document.and'))
                  )}
                </Text>
              ))}
            </Text>
            <Text>
              {String(
                translate(
                  `lastWill.stepFour.document.p6MarriedSpouse.${
                    isOnlyChildFromOtherMarriage
                      ? 'oneChild'
                      : 'multipleChildren'
                  }.receives`
                )
              )}
            </Text>
            <Text>
              {String(
                translate('lastWill.stepFour.document.p6MarriedSpouse.final')
              )}
            </Text>
          </Text>
        )}
      </View>
    </View>
  );
};

export default SpouseVariant;

const getConjunction = (i: number, length: number, text: string) => {
  if (i === length - 2) {
    return text;
  }
  if (i === length - 1) {
    return '';
  }
  return '';
};
