import { Box, Typography, Stack, Button } from '@mui/material';
import React, { useState } from 'react';
import { IGoods } from '#/types/propertyAssets';
import { useGetBeneficiary } from '#/api/beneficiaries';
import Iconify from '#/components/shared/ui/Iconify';
import useLocales from '#/hooks/useLocales';
import {
  invalidateServicesStatsQueries,
  useDeleteGood,
} from '#/api/servicesQueries';
import DeleteModal from '#/components/pages/LastWill/steps/content/Inheritors/childrens/DeleteModal';

interface Props extends IGoods {
  onEdit: () => void;
}
const GoodInfo = ({
  storage_location,
  beneficiary,
  id,
  description,
  onEdit,
}: Props) => {
  const [openModal, setOpenModal] = useState(false);

  const { translate } = useLocales();

  const { data: assignedBeneficiary } = useGetBeneficiary(
    beneficiary,
    !!beneficiary
  );

  const { mutateAsync: deleteGood } = useDeleteGood('last-will', id);

  const handleOpenDeleteDialog = () => setOpenModal(true);
  const handleCloseDeleteDialog = () => setOpenModal(false);

  const handleDelete = async () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    await deleteGood(id).then(() => {
      invalidateServicesStatsQueries.getGoods('last-will');
    });
  };

  return (
    <Box
      sx={{
        my: 2,
        display: {
          sm: 'block',
          md: 'flex',
        },
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="subtitle1" noWrap sx={{ width: '210px' }}>
            {String(translate('global.description'))}:
          </Typography>
          <Stack direction="row" alignItems="center">
            <Typography variant="body2" noWrap>
              {`${description}`}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="subtitle1" noWrap sx={{ width: '210px' }}>
            {String(translate('global.adress'))}:
          </Typography>
          <Stack direction="row" alignItems="center">
            <Typography variant="body2" noWrap>
              {`${storage_location}`}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="subtitle1" noWrap sx={{ width: '210px' }}>
            {String(translate('global.beneficiarie'))}:
          </Typography>
          <Stack direction="row" alignItems="center">
            <Typography variant="body2" noWrap>
              {assignedBeneficiary?.name}
            </Typography>
          </Stack>
        </Stack>
      </Box>
      <Stack direction="row" spacing={1}>
        <Button
          onClick={onEdit}
          variant="outlined"
          size="small"
          startIcon={
            <Iconify icon="octicon:pencil-16" sx={{ fontSize: '1.5rem' }} />
          }
        >
          <Typography variant="subtitle2" fontWeight="light">
            {String(translate('global.edit'))}
          </Typography>
        </Button>
        <Button
          variant="outlined"
          color="error"
          size="small"
          onClick={handleOpenDeleteDialog}
          startIcon={
            <Iconify icon="octicon:trash-16" sx={{ fontSize: '1.5rem' }} />
          }
        >
          <Typography variant="subtitle2" fontWeight="light">
            {String(translate('global.delete'))}
          </Typography>
        </Button>
      </Stack>
      <DeleteModal
        openDeleteDialog={openModal}
        handleCloseDeleteDialog={handleCloseDeleteDialog}
        handleDelete={handleDelete}
        item={String(translate('global.good'))}
      />
    </Box>
  );
};

export default GoodInfo;
