import { Document, Page, Text, View } from '@react-pdf/renderer';
import { Key } from 'react';
import useLocales from '../../../../../../lib/hooks/useLocales';
import styles from '#/components/pages/LivingWill/Steps/content/YourLivingWill/styles';
import { LastWillValues } from '#/types/lastWill';
import { fDate } from '#/utils/formatTime';

import ChildVariant from './MarriedCase/ChildVariant';
import InstitutionVariant from './MarriedCase/InstitutionVariant';
import SpouseVariant from './MarriedCase/SpouseVariant';
import PartnerVariant from '#/components/pages/LastWill/steps/content/YourLastWill/UnMarriedCase/PartnerVariant';
import CommonChildren from '#/components/pages/LastWill/steps/content/YourLastWill/UnMarriedCase/CommonChildren';
import InstitutionChoice from '#/components/pages/LastWill/steps/content/YourLastWill/UnMarriedCase/InstitutionChoice';
import { IGoods } from '#/types/propertyAssets';
import { IBeneficiary } from '#/types/beneficiaries';

type LastWillDocProps = {
  outputValues?: LastWillValues | any;
};
const LastWillDoc = ({ outputValues }: LastWillDocProps) => {
  const { translate } = useLocales();

  const {
    institutionBeneficiaries,
    beneficiaries,
    assets,
    user,
    permitted_law,
    living_partnership,
    partner_or_spouse_first_name,
    partner_or_spouse_last_name,
    children,
    partner_property,
    partner_property_address,
    partner_or_spouse_date_of_birth,
    willExecutor,
    willExecutor2,
    inheritanceGoods,
  } = outputValues;

  const isOneInstitution = institutionBeneficiaries?.length === 1;

  const address = `${user?.address?.street} ${user?.address
    ?.house_number}, ${user?.address?.zip_code} ${user?.address
    ?.city}, ${String(
    translate(`global.${user?.address?.country.toLowerCase()}`)
  )}`;

  const propertyAddress = `${partner_property_address?.street} ${partner_property_address?.house_number}, ${partner_property_address?.zip_code} ${partner_property_address?.city}, ${String(
    translate(`global.${partner_property_address?.country.toLowerCase()}`)
  )}`;

  const gender = user?.gender === 'M' ? 'male' : 'female';

  const partnerMainInfo = `${partner_or_spouse_first_name} ${partner_or_spouse_last_name}`;
  const partnerBirthday = fDate(
    partner_or_spouse_date_of_birth,
    'dd MMM yyyy',
    'de'
  );

  const unMarriedCase =
    user?.civil_status === 'single' ||
    user?.civil_status === 'divorced' ||
    user?.civil_status === 'widowed';

  const checkSimilarity = (benefiter: string) => {
    const sameAsChildren = children?.some(
      (child: any) =>
        child.first_name === benefiter?.split(' ')[0] &&
        child.last_name === benefiter?.split(' ')[1]
    );
    const sameAsPartner =
      benefiter?.split(' ')[0] === partner_or_spouse_first_name &&
      benefiter?.split(' ')[1] === partner_or_spouse_last_name;

    return sameAsChildren || sameAsPartner;
  };

  return (
    <Document
      pageMode="fullScreen"
      title={String(translate('global.services.lastWill'))}
    >
      <Page size="A4" wrap style={styles.page}>
        <View style={styles.headSection}>
          <Text style={styles.title}>
            {String(translate('lastWill.stepFour.document.title'))}
          </Text>
          <Text>{String(translate('lastWill.stepFour.document.from'))}</Text>
          <View style={{ marginTop: 20 }}>
            <Text style={styles.title}>
              {`${user?.first_name} ${user?.last_name}`}
            </Text>
            <Text>
              {String(translate('lastWill.stepFour.document.born'))}
              {fDate(user?.date_of_birth, 'dd. MMM yyyy', 'de')}
            </Text>
            <Text>
              {String(
                translate('lastWill.stepFour.document.civil_status', {
                  civil_status: translate(
                    `lastWill.stepFour.document.civilStatus.${user?.civil_status}`
                  ),
                })
              )}
            </Text>
            <Text>
              {String(
                translate('lastWill.stepFour.document.address', { address })
              )}
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text>
            {String(
              translate('lastWill.stepFour.document.declare', {
                first_name: user?.first_name,
                last_name: user?.last_name,
              })
            )}
          </Text>
          <View style={styles.pointParagraphs}>
            <Text>1.</Text>
            <View style={styles.pointContent}>
              <Text>
                {String(translate('lastWill.stepFour.document.paragraph1'))}
              </Text>
            </View>
          </View>
          <View style={styles.pointParagraphs}>
            <Text>2.</Text>
            <View style={styles.pointContent}>
              <Text>
                {String(translate('lastWill.stepFour.document.paragraph2'))}
              </Text>
            </View>
          </View>
          {!unMarriedCase && permitted_law === 'registeredPartnership' && (
            <SpouseVariant
              living_partnership={living_partnership}
              gender={gender}
              clientChildrens={children}
              partner_property={partner_property}
              partner_property_address={propertyAddress}
              partnerMainInfo={partnerMainInfo}
              partnerBirthday={partnerBirthday}
            />
          )}
          {!unMarriedCase && permitted_law === 'children' && (
            <ChildVariant
              clientChilds={children}
              gender={gender}
              partnerMainInfo={partnerMainInfo}
              partnerBirthday={partnerBirthday}
            />
          )}
          {!unMarriedCase && permitted_law === 'institution' && (
            <InstitutionVariant
              clientChilds={children}
              institutionBeneficiaries={institutionBeneficiaries}
              isOneInstitution={isOneInstitution}
              living_partnership={living_partnership}
              gender={gender}
              partnerMainInfo={partnerMainInfo}
              partnerBirthday={partnerBirthday}
            />
          )}
          {unMarriedCase && permitted_law === 'registeredPartnership' && (
            <PartnerVariant
              gender={gender}
              propertyAddress={propertyAddress}
              propertyContract={partner_property}
              living_partnership={living_partnership}
              clientChilds={children}
              partnerMainInfo={partnerMainInfo}
              partnerBirthday={partnerBirthday}
            />
          )}
          {unMarriedCase && permitted_law === 'children' && (
            <CommonChildren
              clientChilds={children}
              gender={gender}
              living_partnership={living_partnership}
              partnerMainInfo={partnerMainInfo}
              partnerBirthday={partnerBirthday}
            />
          )}
          {unMarriedCase && permitted_law === 'institution' && (
            <InstitutionChoice
              gender={gender}
              clientChilds={children}
              isOneInstitution={isOneInstitution}
              institutionBeneficiaries={institutionBeneficiaries}
              living_partnership={living_partnership}
            />
          )}
          {inheritanceGoods === 'yes' && assets?.length > 0 && (
            <View style={styles.pointParagraphs}>
              <Text>4.</Text>
              <View style={styles.pointContent}>
                <Text>
                  {String(
                    translate('lastWill.stepFour.document.forFollowing.main')
                  )}
                </Text>
                {assets?.map((a: IGoods, i: Key | null | undefined) => {
                  const benefiter = beneficiaries?.find(
                    (b: IBeneficiary) => b.id === a.beneficiary
                  );
                  return (
                    <Text key={i}>
                      {String(
                        translate(
                          `lastWill.stepFour.document.forFollowing.asset`,
                          {
                            asset: a.description,
                            address: a.storage_location,
                            benefiter: benefiter?.name,
                          }
                        )
                      )}
                      {checkSimilarity(benefiter?.name) && (
                        <Text>
                          {String(
                            translate(
                              `lastWill.stepFour.document.forFollowing.decisionProvision`,
                              {
                                benefiter: benefiter?.name,
                              }
                            )
                          )}
                        </Text>
                      )}
                    </Text>
                  );
                })}
              </View>
            </View>
          )}
          <Text>
            {String(
              translate('lastWill.stepFour.document.willExecutor', {
                executor1: `${willExecutor?.first_name} ${willExecutor?.last_name}`,
                executor2: `${willExecutor2?.first_name} ${willExecutor2?.last_name}`,
              })
            )}
          </Text>
          <Text>{`${user?.first_name} ${user?.last_name}`}</Text>
          <View
            style={{
              ...styles.pointParagraphs,
              gap: 10,
            }}
          >
            <Text>{address}</Text>
            <Text>{fDate(new Date(), 'dd. MMM yyyy', 'de')}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default LastWillDoc;
