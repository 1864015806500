import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import useLocales from '#/hooks/useLocales';
import { governanceQuestions } from '#/components/pages/LastWill/steps/content/stepsData';

const Governance = () => {
  const {
    formState: { errors },
  } = useFormContext();
  const { translate } = useLocales();

  const refs = useRef<{ [key: string]: HTMLElement | null }>({});

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const errorKeys = Object.keys(errors);

      const errorElements = errorKeys
        .map((key) => refs.current[key])
        .filter((ref) => ref !== undefined && ref !== null);

      if (errorElements.length > 0) {
        const highestErrorElement = errorElements.reduce((highest, current) => {
          const currentTop = current ? current.getBoundingClientRect().top : 0;
          const highestTop = highest ? highest.getBoundingClientRect().top : 0;

          return currentTop < highestTop ? current : highest;
        }, errorElements[0]);

        if (highestErrorElement) {
          highestErrorElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        }
      }
    }
  }, [errors]);

  const tNonMGovernance = governanceQuestions.map((question, i) => ({
    ...question,
    question: translate(`lastWill.stepThree.questions.${i}.question`),
  }));

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Stack spacing={5}>
        {tNonMGovernance.map((question) => (
          <div
            key={`${question.value}`}
            ref={(ref) => {
              refs.current[`${question.children.value}`] = ref;
            }}
          >
            <Stack spacing={1.5}>
              <Typography variant="subtitle1">
                {String(question.question)}
              </Typography>
              {question.children.content()}
            </Stack>
          </div>
        ))}
      </Stack>
    </Box>
  );
};

export default Governance;
