import Inheritors from '#/components/pages/LastWill/steps/content/Inheritors/Inheritors';

import RegistredPartnership from './Inheritors/childrens/RegistredPartnership';
import PropertyContract from './Inheritors/childrens/PropertyContract';
import Distribution from './Distribution/Distribution';
import Governance from './Governance/Governance';
import GovernancePersonForm from './Governance/childrens/GovernancePersonForm';
import YourLastWill from './YourLastWill/YourLastWill';
// import Beneficiaries from './Inheritors/childrens/Beneficiary/Beneficiaries';
import GoodsDistribution from '#/components/pages/LastWill/steps/content/Distribution/childrens/GoodsDistribution/GoodsDistribution';
import { LastWillValues } from '#/types/lastWill';
import Child from '#/components/pages/LastWill/steps/content/Inheritors/childrens/Children/Child';
import InstitutionsList from '#/components/pages/LastWill/steps/content/Distribution/childrens/InstitutionsList/InstitutionsList';

export const stepsData = [
  {
    label: 'Inheritors',
    title: 'What is it about?',
    content: () => <Inheritors />,
  },
  {
    label: 'Distribution',
    title: 'Define distribution',
    content: () => <Distribution />,
  },
  {
    label: 'Governance',
    title: 'Define governance',
    content: () => <Governance />,
  },
  {
    label: 'Your last will',
    title: 'Now what?',
    content: (outputValues?: LastWillValues) => (
      <YourLastWill outputValues={outputValues} />
    ),
  },
];

export const inheritorsSingleVersion = [
  {
    value: 'living_partnership',
    id: '1',
    question: 'Are you living in a registered partnership?',
    answers: [
      {
        label: 'Yes',
        value: 'yes',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
    children: {
      value: 'registeredPartnership',
      content: () => <RegistredPartnership />,
    },
  },
  {
    value: 'marriage_or_partnership_contract',
    id: '2',
    question:
      'Have you concluded a property contract (registered partnership) ?',
    answers: [
      {
        label: 'Yes',
        value: 'yes',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
    // children: {
    //   value: 'marriage_or_partnership_address',
    //   content: <PropertyContract />,
    // },
  },
  {
    value: 'partner_property',
    id: '3',
    question: 'Do you have a property/apartment to inherit to your partner?',
    answers: [
      {
        label: 'Yes',
        value: 'yes',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
    children: {
      value: 'partner_property_address',
      content: () => <PropertyContract />,
    },
  },
  {
    value: 'have_children',
    id: '4',
    question: 'Do you have children ?',
    answers: [
      {
        label: 'Yes',
        value: 'yes',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
    children: {
      value: 'children_no',
      content: (living_partnership: string) => (
        <Child isLivingPartnership={living_partnership} />
      ),
    },
  },
  // {
  //   value: 'benefiters',
  //   id: '5',
  //   question: 'Would you like to benefit other persons or institutions ?',
  //   answers: [
  //     {
  //       label: 'Yes',
  //       value: 'yes',
  //     },
  //     {
  //       label: 'No',
  //       value: 'no',
  //     },
  //   ],
  //   children: {
  //     value: 'beneficiary',
  //     content: <Beneficiaries />,
  //   },
  // },
];

export const inheritorsMarriedVersion = [
  {
    value: 'spouseDetails',
    id: '1',
    question:
      'We understand you are married. Can you provide us with the details of your spouse?',
    answers: [],
    children: {
      value: 'registeredPartnership',
      content: () => <RegistredPartnership />,
    },
  },
  {
    value: 'marriage_or_partnership_contract',
    id: '2',
    question: 'Have you concluded a marriage contract or a property contract?',
    answers: [
      {
        label: 'Yes',
        value: 'yes',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
    // children: {
    //   value: 'marriage_or_partnership_address',
    //   content: <PropertyContract />,
    // },
  },
  {
    value: 'partner_property',
    id: '3',
    question: 'Do you have a property/apartment to inherit to your partner?',
    answers: [
      {
        label: 'Yes',
        value: 'yes',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
    children: {
      value: 'partner_property_address',
      content: () => <PropertyContract />,
    },
  },
  {
    value: 'have_children',
    id: '4',
    question: 'Do you have children ?',
    answers: [
      {
        label: 'Yes',
        value: 'yes',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
    children: {
      value: 'children_no',
      content: (living_partnership: string) => (
        <Child isLivingPartnership={living_partnership} />
      ),
    },
  },
  // {
  //   value: 'benefiters',
  //   id: '6',
  //   question: 'Would you like to benefit other persons or institutions ?',
  //   answers: [
  //     {
  //       label: 'Yes',
  //       value: 'yes',
  //     },
  //     {
  //       label: 'No',
  //       value: 'no',
  //     },
  //   ],
  //   children: {
  //     value: 'beneficiary',
  //     content: <Beneficiaries />,
  //   },
  // },
];

export const inheritorsDivorcedVersion = [
  {
    value: 'living_partnership',
    id: '1',
    question: 'Are you living in a registered partnership?',
    answers: [
      {
        label: 'Yes',
        value: 'yes',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
    children: {
      value: 'registeredPartnership',
      content: () => <RegistredPartnership />,
    },
  },
  {
    value: 'have_children',
    id: '2',
    question:
      'We understand that you are divorced. Do you have children from the common marriage?',
    answers: [
      {
        label: 'Yes',
        value: 'yes',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
    children: {
      value: 'children_no',
      content: (living_partnership: string) => (
        <Child isLivingPartnership={living_partnership} />
      ),
    },
  },
  // {
  //   value: 'benefiters',
  //   id: '2',
  //   question: 'Would you like to benefit other persons or institutions ?',
  //   answers: [
  //     {
  //       label: 'Yes',
  //       value: 'yes',
  //     },
  //     {
  //       label: 'No',
  //       value: 'no',
  //     },
  //   ],
  //   children: {
  //     value: 'beneficiary',
  //     content: <Beneficiaries />,
  //   },
  // },
];

export const distributionQuestions = [
  {
    value: 'permitted_law',
    id: '5',
    question:
      'To the extent permitted by law, I would like as much of my estate as possible to:',
    answers: [
      {
        label: 'My spouse/registered partner/my life partner',
        value: 'registeredPartnership',
      },
      {
        label: 'My children',
        value: 'children',
      },
      {
        label: 'An institution of my choice',
        value: 'institution',
      },
    ],
    children: {
      value: 'institutions_list',
      content: () => <InstitutionsList />,
    },
  },
  // {
  //   value: 'assetOrProperty',
  //   id: '6',
  //   question:
  //     'Do you have assets that you would like to donate to specific individuals?',
  //   answers: [
  //     {
  //       label: 'Yes',
  //       value: 'yes',
  //     },
  //     {
  //       label: 'No',
  //       value: 'no',
  //     },
  //   ],
  //   children: {
  //     value: 'assetInfo',
  //     content: <PropertyAssetForm />,
  //   },
  // },
  {
    value: 'inheritanceGoods',
    id: '6',
    question:
      'Do you have different inheritance goods that you would like to donate to different people?',
    answers: [
      {
        label: 'Yes',
        value: 'yes',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
    children: {
      value: 'good',
      content: () => <GoodsDistribution />,
    },
  },
];

export const governanceQuestions = [
  {
    value: 'will_executor',
    id: '8',
    question: 'Who should be the executor of your will?',
    children: {
      value: 'governancePerson',
      content: () => <GovernancePersonForm />,
    },
  },
];
