import { Button, MenuItem, Stack, Typography } from '@mui/material';
import React, { BaseSyntheticEvent } from 'react';
import { LoadingButton } from '@mui/lab';
import { RHFSelect, RHFTextField } from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import AutoAddressPicker from '#/components/shared/hook-form/AutoAddressPicker';
import Iconify from '#/components/shared/ui/Iconify';

const beneficiaryCategory = [
  { id: '2', value: 'company' },
  { id: '3', value: 'foundation' },
  { id: '4', value: 'association' },
];

const countries = [
  // { code: 'DE', label: 'Germany' },
  // { code: 'AT', label: 'Austria' },
  { code: 'CH', label: 'global.switzerland' },
  // { code: 'FR', label: 'France' },
];

type Props = {
  handleSubmit: (e?: BaseSyntheticEvent | undefined) => Promise<void>;
  closeForm: () => void;
  formState: {
    errors: any;
  };
  isSubmitDisabled?: boolean;
  setNewBeneficiary?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
};

export default function InstitutionForm({
  handleSubmit,
  closeForm,
  formState,
  isSubmitDisabled,
  setNewBeneficiary,
}: Props) {
  const { translate } = useLocales();

  const fieldNames = {
    street: 'route',
    house_number: 'street_number',
    zip_code: 'postal_code',
    city: 'locality',
  };
  const isStreetError = Boolean(formState?.errors?.street);

  return (
    <Stack spacing={2}>
      <RHFSelect
        name="beneficiary_type"
        label={String(
          translate('global.formLabels.beneficiarieCategories.selectCategory')
        )}
      >
        {beneficiaryCategory.map((category) => (
          <MenuItem key={category.id} value={category.value}>
            {String(
              translate(
                `global.formLabels.beneficiarieCategories.${category.value}`
              )
            )}
          </MenuItem>
        ))}
      </RHFSelect>
      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        spacing={2}
      >
        <RHFTextField
          name="name"
          label={String(translate('global.formLabels.name'))}
        />
      </Stack>
      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        spacing={2}
      >
        <Stack sx={{ width: '100%' }}>
          <AutoAddressPicker
            fieldNames={fieldNames}
            streetError={isStreetError}
            name="street"
          />
        </Stack>
        <RHFTextField
          name="house_number"
          label={String(translate('global.formLabels.houseNumber'))}
        />
      </Stack>
      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        spacing={2}
      >
        <RHFTextField
          name="zip_code"
          label={String(translate('global.formLabels.postalCode'))}
        />
        <RHFTextField
          name="city"
          label={String(translate('global.formLabels.city'))}
        />
      </Stack>
      <RHFSelect
        name="country"
        label={String(translate('global.formLabels.country'))}
        value="CH"
        defaultValue={countries.find((country) => country.code === 'CH')?.code}
      >
        {countries.map((country) => (
          <MenuItem key={country.code} value={country.code}>
            {String(translate(country.label))}
          </MenuItem>
        ))}
      </RHFSelect>
      <Stack
        sx={{
          width: '100%',
        }}
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        alignItems="center"
        spacing={2}
      >
        <LoadingButton
          variant="contained"
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
          sx={{
            width: '120px',
          }}
          size="small"
          color="secondary"
          startIcon={<Iconify icon="mdi:content-save" />}
        >
          <Typography noWrap>{String(translate('global.save'))}</Typography>
        </LoadingButton>
        <Button
          variant="outlined"
          onClick={() => {
            closeForm();
            setNewBeneficiary?.(false);
          }}
          startIcon={<Iconify icon="mdi:close" />}
        >
          <Typography variant="caption">
            {String(translate('global.cancel'))}
          </Typography>
        </Button>
      </Stack>
    </Stack>
  );
}
