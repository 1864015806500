import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useGetGoods } from '#/api/servicesQueries';
import GoodInfo from '#/components/pages/LastWill/steps/content/Distribution/childrens/GoodsDistribution/GoodInfo';
import GoodsForm from '#/components/pages/LastWill/steps/content/Distribution/childrens/GoodsDistribution/GoodsForm';
import useLocales from '#/hooks/useLocales';
import Iconify from '#/components/shared/ui/Iconify';

const GoodsDistribution = () => {
  const [isInitial, setIsInitial] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [goodID, setGoodID] = useState<string | undefined | number>();
  const [newBeneficiary, setNewBeneficiary] = useState<boolean | undefined>(
    false
  );

  const { data: goods } = useGetGoods('last-will');

  const handleEdit = (id: number | string | undefined) => {
    setIsEdit(true);
    if (id) {
      setGoodID(id);
    }
  };

  const handleAddNew = () => {
    setAddNew(true);
  };

  useEffect(() => {
    if (goods?.length === 0) {
      setIsInitial(true);
    }
    if (goods?.length) {
      setIsInitial(false);
    }
  }, [goods]);

  const { translate } = useLocales();

  return (
    <Box>
      {!isInitial && !isEdit && !addNew && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {goods?.map((good) => (
            <GoodInfo
              key={good.id}
              id={good.id}
              storage_location={good.storage_location}
              description={good.description}
              beneficiary={good.beneficiary}
              onEdit={() => handleEdit(good.id)}
            />
          ))}
        </>
      )}
      {isInitial && (
        <GoodsForm
          setNewBeneficiary={setNewBeneficiary}
          newBeneficiary={newBeneficiary}
        />
      )}
      {isEdit && (
        <GoodsForm
          isEdit={isEdit}
          goodID={goodID}
          setIsEdit={setIsEdit}
          setNewBeneficiary={setNewBeneficiary}
          newBeneficiary={newBeneficiary}
        />
      )}
      {addNew && (
        <GoodsForm
          setAddNew={setAddNew}
          setNewBeneficiary={setNewBeneficiary}
          newBeneficiary={newBeneficiary}
        />
      )}
      <Divider
        sx={{
          my: 2,
        }}
      />

      {!isInitial && !isEdit && !addNew && (
        <Button
          onClick={handleAddNew}
          variant="contained"
          size="small"
          color="secondary"
          sx={{
            my: 2,
          }}
          startIcon={<Iconify icon="mdi:plus-circle" />}
        >
          <Typography variant="body2" noWrap>
            {String(translate('global.addItem'))}
          </Typography>
        </Button>
      )}
    </Box>
  );
};

export default GoodsDistribution;
