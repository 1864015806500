import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { RHFDatePicker, RHFTextField } from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import {
  useGetRepresentative,
  useGetRepresentatives,
} from '#/api/representativeQueries';
import useAuth from '#/hooks/useAuth';

const RegistredPartnership = () => {
  const { translate } = useLocales();

  const { setValue } = useFormContext();

  const { user } = useAuth();

  const { data: representatives } = useGetRepresentatives();

  const representativePartner = representatives?.find(
    (representative) => representative.relationship === 'husband_wife'
  );

  const { data: representative } = useGetRepresentative(
    representativePartner?.id,
    !!representativePartner
  );

  useEffect(() => {
    if (user?.civil_status === 'married' && representative) {
      setValue('partner_or_spouse_first_name', representative.first_name);
      setValue('partner_or_spouse_last_name', representative.last_name);
      setValue('partner_or_spouse_date_of_birth', representative.date_of_birth);
    }
  }, [user?.civil_status, representative]);

  return (
    <Stack spacing={2}>
      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        spacing={2}
      >
        <RHFTextField
          name="partner_or_spouse_first_name"
          label={String(translate('global.formLabels.firstName'))}
        />
        <RHFTextField
          name="partner_or_spouse_last_name"
          label={String(translate('global.formLabels.lastName'))}
        />
      </Stack>
      <RHFDatePicker
        name="partner_or_spouse_date_of_birth"
        label={String(translate('global.formLabels.date_of_birth'))}
      />
    </Stack>
  );
};

export default RegistredPartnership;
