import { Text, View } from '@react-pdf/renderer';
import useLocales from '#/hooks/useLocales';
import styles from '#/components/pages/LivingWill/Steps/content/YourLivingWill/styles';
import { fDate } from '#/utils/formatTime';

type Props = {
  gender: string;
  propertyAddress: string;
  propertyContract: string;
  clientChilds: any[];
  living_partnership: string;
  partnerMainInfo: string;
  partnerBirthday: string;
};

const PartnerVariant = ({
  gender,
  propertyAddress,
  propertyContract,
  clientChilds,
  living_partnership,
  partnerMainInfo,
  partnerBirthday,
}: Props) => {
  const { translate } = useLocales();

  const childFromUnknownPartnership = clientChilds?.filter(
    (child: any) => child?.from_commonMarriage === 'no'
  );

  const childFromOtherPartnership = clientChilds?.filter(
    (child: any) => child?.from_commonMarriage === 'no'
  );

  const childFromCommonPartnership = clientChilds?.filter(
    (child: any) => child?.from_commonMarriage === 'yes'
  );

  const livesInPartnership = living_partnership === 'yes';

  const children = livesInPartnership
    ? childFromCommonPartnership
    : childFromUnknownPartnership;

  const isOnlyChildFromPartnership = children?.length === 1;
  const isOnlyChildFromOtherPartnership =
    childFromOtherPartnership?.length === 1;

  const partnershipKey = livesInPartnership
    ? 'commonPartnership'
    : 'noPartnership';

  const handlePartnership = () => {
    const femalePartner = livesInPartnership && gender === 'male';
    if (livesInPartnership) {
      if (femalePartner) {
        return 'registeredPartnerMale';
      }
      return 'registeredPartnerFemale';
    }
    return gender;
  };

  return (
    <View style={styles.pointParagraphs}>
      <Text>3.</Text>
      <View style={styles.pointContent}>
        <Text>
          {String(
            translate(
              `lastWill.stepFour.document.p4UnmarriedPartner.genderStatus.${handlePartnership()}`
            )
          )}
          {', '}
          {partnerMainInfo}
          {String(
            translate(`lastWill.stepFour.document.p4UnmarriedPartner.born`, {
              birthday: partnerBirthday,
            })
          )}
          {String(
            translate('lastWill.stepFour.document.p4UnmarriedPartner.receives')
          )}
        </Text>
        {propertyContract === 'yes' && (
          <Text>
            {String(
              translate(
                `lastWill.stepFour.document.p4UnmarriedPartner.genderStatus.${
                  gender || 'male'
                }`
              )
            )}{' '}
            {String(
              translate(
                `lastWill.stepFour.document.p4UnmarriedPartner.receivesProperty`
              )
            )}{' '}
            {String(
              translate(
                `lastWill.stepFour.document.p4UnmarriedPartner.theProperty`
              )
            )}{' '}
            {String(
              translate(
                `lastWill.stepFour.document.p4UnmarriedPartner.atTheAddress`
              )
            )}{' '}
            {propertyAddress}{' '}
            {String(
              translate(
                `lastWill.stepFour.document.p4UnmarriedPartner.assignedToProperty`
              )
            )}
          </Text>
        )}

        {children?.length !== 0 && (
          <>
            <Text>
              {String(
                translate(
                  `lastWill.stepFour.document.p5UnmarriedPartnerWithChildren.${partnershipKey}.${
                    isOnlyChildFromPartnership ? 'oneChild' : 'multipleChildren'
                  }.main`
                )
              )}
              <Text style={{ display: 'flex' }}>
                {children?.map((child: any, i: number) => (
                  <Text style={{ marginLeft: 2 }} key={child.id}>
                    {i !== 0 && ' '}
                    {child.first_name} {child.last_name}
                    {String(
                      translate(
                        `lastWill.stepFour.document.p5UnmarriedPartnerWithChildren.${partnershipKey}.${
                          isOnlyChildFromPartnership
                            ? 'oneChild'
                            : 'multipleChildren'
                        }.born`,
                        {
                          birthday: fDate(
                            child?.date_of_birth,
                            'dd. MMM yyyy',
                            'de'
                          ),
                        }
                      )
                    )}
                    {commonPartnershipConjunction(
                      i,
                      children?.length,
                      String(translate('lastWill.stepFour.document.and'))
                    )}
                  </Text>
                ))}
              </Text>
              {String(
                translate(
                  `lastWill.stepFour.document.p5UnmarriedPartnerWithChildren.${partnershipKey}.${
                    isOnlyChildFromPartnership ? 'oneChild' : 'multipleChildren'
                  }.receive`
                )
              )}
            </Text>
            <Text>
              {String(
                translate(
                  `lastWill.stepFour.document.p5UnmarriedPartnerWithChildren.deathCase`,
                  {
                    partner: translate(
                      `lastWill.stepFour.document.p4UnmarriedPartner.genderStatus.${
                        `${gender}1` || 'male1'
                      }`
                    ),
                    childrenCase: translate(
                      `lastWill.stepFour.document.p5UnmarriedPartnerWithChildren.childrenCase.${
                        children?.length > 1
                          ? `multipleChildren.${
                              livesInPartnership
                                ? 'fromCommonMarriage'
                                : 'fromUnknownPartnership'
                            }`
                          : `oneChild.${
                              livesInPartnership
                                ? 'fromCommonMarriage'
                                : 'fromUnknownPartnership'
                            }`
                      }`
                    ),
                  }
                )
              )}
              <Text style={{ display: 'flex' }}>
                {children?.map((child: any, i: number) => (
                  <Text style={{ marginLeft: 2 }} key={child.id}>
                    {i !== 0 && ' '}
                    {child.first_name} {child.last_name}
                    {String(
                      translate(
                        'lastWill.stepFour.document.p5UnmarriedPartnerWithChildren.born',
                        {
                          birthday: fDate(
                            child?.date_of_birth,
                            'dd. MMM yyyy',
                            'de'
                          ),
                        }
                      )
                    )}
                    {deathCaseConjunction(
                      i,
                      children?.length,
                      String(translate('lastWill.stepFour.document.and'))
                    )}
                  </Text>
                ))}
              </Text>
              {!isOnlyChildFromPartnership
                ? String(
                    translate(
                      `lastWill.stepFour.document.p5UnmarriedPartnerWithChildren.childrenCase.allDescendants`
                    )
                  )
                : String(
                    translate(
                      `lastWill.stepFour.document.p5UnmarriedPartnerWithChildren.childrenCase.onesDescendants`
                    )
                  )}
            </Text>
          </>
        )}

        {childFromOtherPartnership?.length !== 0 && livesInPartnership && (
          <>
            <Text>
              {String(
                translate(
                  `lastWill.stepFour.document.p5UnmarriedPartnerWithChildren.otherPartnership.${
                    isOnlyChildFromOtherPartnership
                      ? 'oneChild'
                      : 'multipleChildren'
                  }.main`
                )
              )}
              <Text style={{ display: 'flex' }}>
                {childFromOtherPartnership?.map((child: any, i: number) => (
                  <Text key={child.id}>
                    {i !== 0 && ' '}
                    {child.first_name} {child.last_name}
                    {String(
                      translate(
                        `lastWill.stepFour.document.p5UnmarriedPartnerWithChildren.otherPartnership.${
                          isOnlyChildFromOtherPartnership
                            ? 'oneChild'
                            : 'multipleChildren'
                        }.born`,
                        {
                          birthday: fDate(
                            child?.date_of_birth,
                            'dd. MMM yyyy',
                            'de'
                          ),
                        }
                      )
                    )}
                    {otherPartnershipConjunction(
                      i,
                      childFromOtherPartnership?.length,
                      String(translate('lastWill.stepFour.document.and'))
                    )}
                  </Text>
                ))}
              </Text>
              {String(
                translate(
                  `lastWill.stepFour.document.p5UnmarriedPartnerWithChildren.otherPartnership.${
                    isOnlyChildFromOtherPartnership
                      ? 'oneChild'
                      : 'multipleChildren'
                  }.receive`
                )
              )}
            </Text>
            <Text>
              {String(
                translate(
                  `lastWill.stepFour.document.p5UnmarriedPartnerWithChildren.deathCase`,
                  {
                    partner: translate(
                      `lastWill.stepFour.document.p4UnmarriedPartner.genderStatus.${
                        `${gender}1` || 'male1'
                      }`
                    ),
                    childrenCase: translate(
                      `lastWill.stepFour.document.p5UnmarriedPartnerWithChildren.childrenCase.${
                        isOnlyChildFromOtherPartnership
                          ? 'oneChild.fromOtherMarriage'
                          : `multipleChildren.fromOtherMarriage`
                      }`
                    ),
                  }
                )
              )}
              <Text style={{ display: 'flex' }}>
                {childFromOtherPartnership?.map((child: any, i) => (
                  <Text style={{ marginLeft: 2 }} key={child.id}>
                    {i !== 0 && ' '}
                    {child.first_name} {child.last_name}
                    {String(
                      translate(
                        'lastWill.stepFour.document.p5UnmarriedPartnerWithChildren.born',
                        {
                          birthday: fDate(
                            child?.date_of_birth,
                            'dd. MMM yyyy',
                            'de'
                          ),
                        }
                      )
                    )}
                    {otherPartnershipConjunction(
                      i,
                      childFromOtherPartnership?.length,
                      String(translate('lastWill.stepFour.document.and'))
                    )}
                  </Text>
                ))}
              </Text>
              {childFromOtherPartnership?.length > 1
                ? String(
                    translate(
                      `lastWill.stepFour.document.p5UnmarriedPartnerWithChildren.childrenCase.allDescendants`
                    )
                  )
                : String(
                    translate(
                      `lastWill.stepFour.document.p5UnmarriedPartnerWithChildren.childrenCase.onesDescendants`
                    )
                  )}
            </Text>
          </>
        )}
      </View>
    </View>
  );
};

export default PartnerVariant;

function commonPartnershipConjunction(
  index: number,
  length: number,
  text: string
) {
  if (index === length - 2) {
    return text;
  }
  if (index === length - 1) {
    return '';
  }
  return '';
}

function otherPartnershipConjunction(
  index: number,
  length: number,
  text: string
) {
  if (index === length - 2) {
    return text;
  }
  if (index === length - 1) {
    return '';
  }
  return '';
}

function deathCaseConjunction(index: number, length: number, text: string) {
  if (index === length - 2) {
    return text;
  }
  if (index === length - 1) {
    return '';
  }
  return ', ';
}
