import { View, Text } from '@react-pdf/renderer';
import useLocales from '#/hooks/useLocales';
import styles from '#/components/pages/LivingWill/Steps/content/YourLivingWill/styles';
import { fDate } from '#/utils/formatTime';

type Props = {
  clientChilds: any[];
  isOneInstitution: boolean;
  institutionBeneficiaries: any[];
  gender: string;
  living_partnership: string;
};
const InstitutionChoice = ({
  clientChilds,
  isOneInstitution,
  institutionBeneficiaries,
  gender,
  living_partnership,
}: Props) => {
  const { translate } = useLocales();
  const isOneChild = clientChilds?.length === 1;

  const childFromUnknownPartnership = clientChilds?.filter(
    (child) => child?.from_commonMarriage === 'no'
  );

  const childFromOtherPartnership = clientChilds?.filter(
    (child) => child?.from_commonMarriage === 'no'
  );

  const childFromCommonPartnership = clientChilds?.filter(
    (child) => child?.from_commonMarriage === 'yes'
  );

  const livesInPartnership = living_partnership === 'yes';

  const children = livesInPartnership
    ? childFromCommonPartnership
    : childFromUnknownPartnership;

  const partnershipKey = livesInPartnership
    ? 'commonPartnership'
    : 'noPartnership';

  return (
    <View style={styles.pointParagraphs}>
      <Text>3.</Text>
      <View style={styles.pointContent}>
        {institutionBeneficiaries && (
          <Text>
            {String(
              translate(
                `lastWill.stepFour.document.p4Institution.${
                  isOneInstitution ? 'oneInstitution' : 'multipleInstitutions'
                }.main`
              )
            )}{' '}
            <Text style={{ display: 'flex' }}>
              {institutionBeneficiaries?.map((institution, i) => (
                <Text style={{ marginLeft: 2 }} key={institution.id}>
                  {institution.name}
                  {getInstitutionConjunction(
                    i,
                    institutionBeneficiaries?.length,
                    String(
                      translate('lastWill.stepFour.document.p4Institution.and')
                    )
                  )}
                </Text>
              ))}
            </Text>
            {String(
              translate(
                `lastWill.stepFour.document.p4Institution.${
                  isOneInstitution ? 'oneInstitution' : 'multipleInstitutions'
                }.receives`
              )
            )}
            {institutionBeneficiaries?.length > 1
              ? String(
                  translate('lastWill.stepFour.document.p4Institution.finals')
                )
              : String(
                  translate('lastWill.stepFour.document.p4Institution.final')
                )}
          </Text>
        )}

        {children?.length !== 0 && (
          <Text>
            {String(
              translate(
                `lastWill.stepFour.document.unmarriedInstitutionChoice.${partnershipKey}.${
                  isOneChild ? 'oneChild' : 'multipleChildren'
                }.main`
              )
            )}
            <Text style={{ display: 'flex' }}>
              {children?.map((child, i) => (
                <Text style={{ marginLeft: 2 }} key={child.id}>
                  {i !== 0 && ' '}
                  {child.first_name} {child.last_name}
                  {String(
                    translate(
                      `lastWill.stepFour.document.unmarriedInstitutionChoice.${partnershipKey}.${
                        isOneChild ? 'oneChild' : 'multipleChildren'
                      }.born`,
                      {
                        birthday: fDate(
                          child?.date_of_birth,
                          'dd. MMM yyyy',
                          'de'
                        ),
                      }
                    )
                  )}
                  {getChildrenConjunction(
                    i,
                    childFromCommonPartnership?.length,
                    String(translate('lastWill.stepFour.document.and'))
                  )}
                </Text>
              ))}
            </Text>
            {String(
              translate(
                `lastWill.stepFour.document.unmarriedInstitutionChoice.commonPartnership.${
                  isOneChild ? 'oneChild' : 'multipleChildren'
                }.receive`
              )
            )}
          </Text>
        )}

        {childFromOtherPartnership?.length !== 0 && livesInPartnership && (
          <Text>
            {String(
              translate(
                `lastWill.stepFour.document.unmarriedInstitutionChoice.otherPartnership.${
                  isOneChild ? 'oneChild' : 'multipleChildren'
                }.main`
              )
            )}
            <Text style={{ display: 'flex' }}>
              {childFromOtherPartnership?.map((child, i) => (
                <Text style={{ marginLeft: 2 }} key={child.id}>
                  {i !== 0 && ' '}
                  {child.first_name} {child.last_name}
                  {String(
                    translate(
                      `lastWill.stepFour.document.unmarriedInstitutionChoice.otherPartnership.${
                        isOneChild ? 'oneChild' : 'multipleChildren'
                      }.born`,
                      {
                        birthday: fDate(
                          child?.date_of_birth,
                          'dd. MMM yyyy',
                          'de'
                        ),
                      }
                    )
                  )}
                  {getChildrenConjunction(
                    i,
                    childFromOtherPartnership?.length,
                    String(translate('lastWill.stepFour.document.and'))
                  )}
                </Text>
              ))}
            </Text>
            {String(
              translate(
                `lastWill.stepFour.document.unmarriedInstitutionChoice.otherPartnership.${
                  isOneChild ? 'oneChild' : 'multipleChildren'
                }.receive`
              )
            )}
          </Text>
        )}

        {livesInPartnership && (
          <Text>
            {String(
              translate(
                `lastWill.stepFour.document.p4UnmarriedPartner.genderStatus.${
                  gender || 'male'
                }`
              )
            )}{' '}
            {String(translate(`lastWill.stepFour.document.receivesNothing`))}
          </Text>
        )}
      </View>
    </View>
  );
};

export default InstitutionChoice;

const getInstitutionConjunction = (i: number, length: number, text: string) => {
  if (i === length - 2) {
    return text;
  }
  if (i === length - 1) {
    return ' ';
  }
  return ', ';
};

const getChildrenConjunction = (i: number, length: number, text: string) => {
  if (i === length - 2) {
    return text;
  }
  if (i === length - 1) {
    return '';
  }
  return '';
};
