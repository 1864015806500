import { Text, View } from '@react-pdf/renderer';
import useLocales from '#/hooks/useLocales';
import styles from '#/components/pages/LivingWill/Steps/content/YourLivingWill/styles';
import { fDate } from '#/utils/formatTime';

type Props = {
  clientChilds: any[];
  gender: string;
  living_partnership: string;
  partnerMainInfo: string;
  partnerBirthday: string;
};
const CommonChildren = ({
  clientChilds,
  gender,
  living_partnership,
  partnerMainInfo,
  partnerBirthday,
}: Props) => {
  const { translate } = useLocales();
  const isOneChild = clientChilds?.length === 1;

  const childFromUnknownPartnership = clientChilds?.filter(
    (child: any) => child?.from_commonMarriage === 'no'
  );

  const childFromOtherPartnership = clientChilds?.filter(
    (child: any) => child?.from_commonMarriage === 'no'
  );

  const childFromCommonPartnership = clientChilds?.filter(
    (child: any) => child?.from_commonMarriage === 'yes'
  );

  const livesInPartnership = living_partnership === 'yes';

  const children = livesInPartnership
    ? childFromCommonPartnership
    : childFromUnknownPartnership;

  const isOnlyChildFromPartnership = children?.length === 1;
  const isOnlyChildFromOtherPartnership =
    childFromOtherPartnership?.length === 1;

  const partnershipKey = livesInPartnership
    ? 'commonPartnership'
    : 'noPartnership';

  const getConjunction = (i: number, length: number) => {
    if (i === length - 2) {
      return String(translate('lastWill.stepFour.document.and'));
    }
    if (i === length - 1) {
      return '';
    }
    return '';
  };

  return (
    <View style={styles.pointParagraphs}>
      <Text>3.</Text>

      <View style={styles.pointContent}>
        {children?.length !== 0 && (
          <Text>
            {String(
              translate(
                `lastWill.stepFour.document.unmarriedChildrenChoice.${partnershipKey}.${
                  isOnlyChildFromPartnership ? 'oneChild' : 'multipleChildren'
                }.main`
              )
            )}
            <Text style={{ display: 'flex' }}>
              {children?.map((child: any, i: number) => (
                <Text style={{ marginLeft: 2 }} key={child.id}>
                  {i !== 0 && ' '}
                  {child.first_name} {child.last_name}
                  {String(
                    translate(
                      `lastWill.stepFour.document.unmarriedChildrenChoice.${partnershipKey}.${
                        isOneChild ? 'oneChild' : 'multipleChildren'
                      }.born`,
                      {
                        birthday: fDate(
                          child?.date_of_birth,
                          'dd. MMM yyyy',
                          'de'
                        ),
                      }
                    )
                  )}
                  {getConjunction(i, children?.length)}
                </Text>
              ))}
              {String(
                translate(
                  `lastWill.stepFour.document.unmarriedChildrenChoice.${partnershipKey}.${
                    isOnlyChildFromPartnership ? 'oneChild' : 'multipleChildren'
                  }.receives`
                )
              )}{' '}
              {String(
                translate(
                  `lastWill.stepFour.document.unmarriedChildrenChoice.${partnershipKey}.quota`
                )
              )}
            </Text>
          </Text>
        )}

        {childFromOtherPartnership?.length !== 0 && livesInPartnership && (
          <Text>
            {String(
              translate(
                `lastWill.stepFour.document.unmarriedChildrenChoice.otherPartnership.${
                  isOnlyChildFromOtherPartnership
                    ? 'oneChild'
                    : 'multipleChildren'
                }.main`
              )
            )}
            <Text style={{ display: 'flex' }}>
              {childFromOtherPartnership?.map((child: any, i) => (
                <Text style={{ marginLeft: 2 }} key={child.id}>
                  {i !== 0 && ' '}
                  {child.first_name} {child.last_name}
                  {String(
                    translate(
                      `lastWill.stepFour.document.unmarriedChildrenChoice.otherPartnership.${
                        isOnlyChildFromOtherPartnership
                          ? 'oneChild'
                          : 'multipleChildren'
                      }.born`,
                      {
                        birthday: fDate(
                          child?.date_of_birth,
                          'dd. MMM yyyy',
                          'de'
                        ),
                      }
                    )
                  )}
                  {getConjunction(i, childFromOtherPartnership?.length)}
                </Text>
              ))}
              {String(
                translate(
                  `lastWill.stepFour.document.unmarriedChildrenChoice.otherPartnership.${
                    isOnlyChildFromOtherPartnership
                      ? 'oneChild'
                      : 'multipleChildren'
                  }.receives`
                )
              )}{' '}
              {String(
                translate(
                  `lastWill.stepFour.document.unmarriedChildrenChoice.otherPartnership.quota`
                )
              )}
            </Text>
          </Text>
        )}
        {livesInPartnership && (
          <Text>
            {String(
              translate(
                `lastWill.stepFour.document.p4UnmarriedPartner.genderStatus.${
                  gender || 'male'
                }`
              )
            )}
            {', '}
            {partnerMainInfo}
            {String(
              translate(`lastWill.stepFour.document.p4UnmarriedPartner.born`, {
                birthday: partnerBirthday,
              })
            )}
            {String(translate(`lastWill.stepFour.document.receivesNothing`))}
          </Text>
        )}
      </View>
    </View>
  );
};

export default CommonChildren;
