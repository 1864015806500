import { View, Text } from '@react-pdf/renderer';
import styles from '#/components/pages/LivingWill/Steps/content/YourLivingWill/styles';
import useLocales from '#/hooks/useLocales';
import { fDate } from '#/utils/formatTime';

type Props = {
  clientChilds: any[];
  gender: string;
  partnerMainInfo: string;
  partnerBirthday: string;
};
const ChildVariant = ({
  clientChilds,
  gender,
  partnerBirthday,
  partnerMainInfo,
}: Props) => {
  const { translate } = useLocales();

  const childFromCommonMarriage = clientChilds?.filter(
    (child: any) => child?.from_commonMarriage === 'yes'
  );

  const childFromOtherMarriage = clientChilds?.filter(
    (child: any) => child?.from_commonMarriage === 'no'
  );

  const isOneChildFromCommon = childFromCommonMarriage?.length === 1;
  const isOneChildFromOther = childFromOtherMarriage?.length === 1;

  const getConjunction = (i: number, length: number) => {
    if (i === length - 2) {
      return String(translate('lastWill.stepFour.document.and'));
    }
    if (i === length - 1) {
      return '';
    }
    return '';
  };

  return (
    <View style={styles.pointParagraphs}>
      <Text>3.</Text>
      <View style={styles.pointContent}>
        {childFromCommonMarriage?.length !== 0 && (
          <Text>
            {String(
              translate(
                `lastWill.stepFour.document.p4Child.commonMarriage.${
                  isOneChildFromCommon ? 'oneChild' : 'multipleChildren'
                }.main`
              )
            )}
            <Text style={{ display: 'flex' }}>
              {childFromCommonMarriage?.map((child: any, i) => (
                <Text style={{ marginLeft: 2 }} key={child.id}>
                  {i !== 0 && ' '}
                  {child.first_name} {child.last_name}
                  {String(
                    translate(
                      `lastWill.stepFour.document.p4Child.commonMarriage.${
                        isOneChildFromCommon ? 'oneChild' : 'multipleChildren'
                      }.born`,
                      {
                        birthday: fDate(
                          child?.date_of_birth,
                          'dd. MMM yyyy',
                          'de'
                        ),
                      }
                    )
                  )}
                  {getConjunction(i, childFromCommonMarriage?.length)}
                </Text>
              ))}
            </Text>
            {String(
              translate(
                `lastWill.stepFour.document.p4Child.commonMarriage.${
                  isOneChildFromCommon ? 'oneChild' : 'multipleChildren'
                }.receive`
              )
            )}
          </Text>
        )}

        {childFromOtherMarriage?.length !== 0 && (
          <Text>
            {String(
              translate(
                `lastWill.stepFour.document.p4Child.otherMarriage.${
                  isOneChildFromOther ? 'oneChild' : 'multipleChildren'
                }.main`
              )
            )}
            <Text style={{ display: 'flex' }}>
              {childFromOtherMarriage?.map((child: any, i) => (
                <Text style={{ marginLeft: 2 }} key={child.id}>
                  {i !== 0 && ' '}
                  {child.first_name} {child.last_name}
                  {String(
                    translate(
                      `lastWill.stepFour.document.p4Child.otherMarriage.${
                        isOneChildFromOther ? 'oneChild' : 'multipleChildren'
                      }.born`,
                      {
                        birthday: fDate(
                          child?.date_of_birth,
                          'dd. MMM yyyy',
                          'de'
                        ),
                      }
                    )
                  )}
                  {getConjunction(i, childFromOtherMarriage?.length)}
                </Text>
              ))}
            </Text>
            {String(
              translate(
                `lastWill.stepFour.document.p4Child.otherMarriage.${
                  isOneChildFromOther ? 'oneChild' : 'multipleChildren'
                }.receive`
              )
            )}
          </Text>
        )}
        <Text>
          {String(
            translate(
              `lastWill.stepFour.document.p4Child.partnerCase.${
                gender || 'male'
              }`
            )
          )}
          {', '}
          {partnerMainInfo}
          {String(
            translate('lastWill.stepFour.document.p4Child.partnerCase.born', {
              birthday: partnerBirthday,
            })
          )}
          {String(
            translate(
              'lastWill.stepFour.document.p4Child.partnerCase.receivesShare'
            )
          )}
        </Text>
      </View>
    </View>
  );
};

export default ChildVariant;
