import Page from '#/components/shared/ui/Page';
import LastWillForm from '#/components/pages/LastWill/LastWillForm';

export default function LastWill() {
  return (
    <Page
      title="Testament erstellen"
      description="Das Testament regelt, was mit Ihrem Vermögen geschehen soll und hilft, Ihren letzten Willen zu sichern und die Abwicklung für Ihre Familie zu erleichtern."
    >
      <LastWillForm />
    </Page>
  );
}
