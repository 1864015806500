import { MenuItem, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { RHFSelect, RHFTextField } from '#/components/shared/hook-form';
import AutoAddressPicker from '#/components/shared/hook-form/AutoAddressPicker';
import useLocales from '#/hooks/useLocales';

const countries = [
  // { code: 'DE', label: 'Germany' },
  // { code: 'AT', label: 'Austria' },
  { code: 'CH', label: 'global.switzerland' },
  // { code: 'FR', label: 'France' },
];

const PropertyContract = () => {
  const { translate } = useLocales();

  const {
    formState: { errors },
  } = useFormContext();

  const fieldNames = {
    'partner_property_address.street': 'route',
    'partner_property_address.house_number': 'street_number',
    'partner_property_address.zip_code': 'postal_code',
    'partner_property_address.city': 'locality',
  };

  const isStreetError = Boolean(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    errors?.partner_property_address?.street
  );

  return (
    <Stack spacing={2}>
      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        spacing={2}
      >
        <Stack sx={{ width: '100%' }}>
          <AutoAddressPicker
            fieldNames={fieldNames}
            streetError={isStreetError}
            name="partner_property_address.street"
          />
        </Stack>
        <RHFTextField
          name="partner_property_address.house_number"
          label={String(translate('global.formLabels.houseNumber'))}
        />
      </Stack>
      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        spacing={2}
      >
        <RHFTextField
          name="partner_property_address.zip_code"
          label={String(translate('global.formLabels.postalCode'))}
        />
        <RHFTextField
          name="partner_property_address.city"
          label={String(translate('global.formLabels.city'))}
        />
      </Stack>
      <RHFSelect
        name="partner_property_address.country"
        label={String(translate('global.formLabels.country'))}
        value="CH"
        defaultValue={countries.find((country) => country.code === 'CH')?.code}
      >
        {countries.map((country) => (
          <MenuItem key={country.code} value={country.code}>
            {String(translate(country.label))}
          </MenuItem>
        ))}
      </RHFSelect>
    </Stack>
  );
};

export default PropertyContract;
